import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Prerequisites`}</h3>
    <p>{`You will need:`}</p>
    <ul>
      <li parentName="ul">{`An account on the smallstep platform. Need one? `}<a parentName="li" {...{
          "href": "https://smallstep.com/signup?product=ssh"
        }}>{`Register here`}</a></li>
      <li parentName="ul">{`Google Admin console privileges for your organization.`}</li>
      <li parentName="ul">{`A single domain name that your users will use, added and verified in the Google Admin console.`}</li>
      <li parentName="ul">{`A Google Cloud Platform (GCP) project in your G Suite Organization.`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://console.cloud.google.com/projectcreate"
            }}>{`Create a GCP project here`}</a>{` if you don't yet have one.`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`Features`}</h3>
    <p>{`The following provisioning features are supported:`}</p>
    <ul>
      <li parentName="ul">{`New Users and Periodical Pull of All Groups`}
        <ul parentName="li">
          <li parentName="ul">{`New users created through G Suite will be created in the third party application.`}</li>
          <li parentName="ul">{`Groups and Memberships will be synchronized periodically`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Push Profile Updates`}
        <ul parentName="li">
          <li parentName="ul">{`Updates made to the user's profile through G Suite will be pushed to the third party application.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Push User Deactivation`}
        <ul parentName="li">
          <li parentName="ul">{`Deactivating the user or disabling the user's access to the application through G Suite will deactivate the user in the third party application.`}</li>
          <li parentName="ul">{`Note: For this application, deactivating a user means removing access to login, but maintaining the user's ssh access information as an inactive user.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Reactivate Users`}
        <ul parentName="li">
          <li parentName="ul">{`User accounts can be reactivated in the application.`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Overview`}</h2>
    <ol>
      <li parentName="ol">{`Create an OAUTH client ID`}</li>
      <li parentName="ol">{`Enter OIDC details into the Smallstep SSH UI`}</li>
      <li parentName="ol">{`Set up API client access`}</li>
      <li parentName="ol">{`Configure G Suite settings in Smallstep SSH UI`}</li>
    </ol>
    <h2>{`Step-by-step Instructions`}</h2>
    <h3>{`Step 1. Create an OAuth Client ID`}</h3>
    <ol>
      <li parentName="ol">{`Configure the OAuth Consent Screen`}
        <ol parentName="li">
          <li parentName="ol">{`In the Google Cloud Console, visit `}<a parentName="li" {...{
              "href": "https://console.cloud.google.com/apis/credentials/consent"
            }}>{`Configure the OAuth Consent Screen`}</a></li>
          <li parentName="ol">{`Choose `}<strong parentName="li">{`User Type: Internal`}</strong></li>
          <li parentName="ol"><strong parentName="li">{`Create`}</strong></li>
          <li parentName="ol">{`Now give your application a name, like `}<strong parentName="li">{`Smallstep SSH`}</strong></li>
          <li parentName="ol">{`Update the `}<strong parentName="li">{`support email address`}</strong>{`, if needed`}</li>
          <li parentName="ol"><strong parentName="li">{`Save`}</strong></li>
        </ol>
      </li>
      <li parentName="ol">{`Create an OAuth Credential`}
        <ol parentName="li">
          <li parentName="ol">{`Visit `}<a parentName="li" {...{
              "href": "https://console.cloud.google.com/apis/credentials/oauthclient"
            }}>{`Create an OAuth Credential`}</a></li>
          <li parentName="ol">{`Choose `}<strong parentName="li">{`Application type: Desktop app`}</strong></li>
          <li parentName="ol">{`Name it `}<strong parentName="li">{`Smallstep SSH`}</strong></li>
          <li parentName="ol"><strong parentName="li">{`Create`}</strong></li>
          <li parentName="ol">{`Copy the Value of `}<strong parentName="li">{`Your Client ID`}</strong>{` and `}<strong parentName="li">{`Your Client Secret`}</strong>{` and save them.`}</li>
        </ol>
      </li>
    </ol>
    <h3>{`Step 2. Enter your OIDC Settings into the Smallstep SSH UI`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Open a new browser tab and log in at `}<inlineCode parentName="p">{`https://smallstep.com/app/[TEAM-NAME]`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter the `}<strong parentName="p">{`client ID`}</strong>{` and `}<strong parentName="p">{`client secret`}</strong>{` from above.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`For the `}<strong parentName="p">{`configuration endpoint`}</strong>{`, enter the following string:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`https://accounts.google.com/.well-known/openid-configuration
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter your primary domain name in the Domain Whitelist.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Enable Single Sign-On`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Keep this tab open for the next step, where you will need the values from `}<strong parentName="p">{`User Group Synchronization Details`}</strong>{`.`}</p>
      </li>
    </ol>
    <h3>{`Step 3. Set up API Client Access`}</h3>
    <ol>
      <li parentName="ol">{`In Google Admin, visit `}<a parentName="li" {...{
          "href": "https://admin.google.com/ac/owl/domainwidedelegation"
        }}>{`Domain-wide Delegation`}</a>{`.`}</li>
      <li parentName="ol">{`Under API clients, choose `}<strong parentName="li">{`Add new`}</strong>{`.`}</li>
      <li parentName="ol">{`For `}<strong parentName="li">{`Client ID`}</strong>{`, fill in the Client ID (a 21-digit number) given to you by smallstep.`}</li>
      <li parentName="ol">{`For `}<strong parentName="li">{`OAuth Scopes`}</strong>{`, enter the comma-delimited API Scopes given to you by smallstep.`}</li>
      <li parentName="ol">{`Choose `}<strong parentName="li">{`Authorize`}</strong>{`.`}</li>
    </ol>
    <p>{`When you're finished, the Manage API Client Access screen page should resemble this:`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/quickstart/g-suite-api-clients.png",
        "alt": null
      }}></img></p>
    <h3>{`Step 4. Configure G Suite Settings in Smallstep`}</h3>
    <ol>
      <li parentName="ol">{`Fill in your `}<strong parentName="li">{`domain name`}</strong>{` and `}<strong parentName="li">{`the email address of a Google Admin`}</strong>{` in your organization, and Save.`}</li>
      <li parentName="ol">{`Wait while we configure and sync your G Suite directory. Please note that G Suite sync is periodical and might take a few minutes.`}</li>
      <li parentName="ol">{`You should see your directory with users and groups synced.`}</li>
    </ol>
    <h2>{`Troubleshooting Tips`}</h2>
    <ul>
      <li parentName="ul">{`Note: When users are deactivated in G Suite, they will be deactivated in Smallstep. Users will not be able to SSH to servers, but their user accounts will remain on smallstep managed hosts. To permanently delete user data on smallstep managed hosts, contact Smallstep Support, (`}<a parentName="li" {...{
          "href": "mailto:support@smallstep.com"
        }}>{`support@smallstep.com`}</a>{`).`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      